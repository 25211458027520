import React from "react"
import Detail1 from "../../images/pwa-steps/TextToCall.svg"
import Detail2 from "../../images/pwa-steps/FreeToUse.svg"
import Detail3 from "../../images/pwa-steps/RealTime.svg"
import Detail4 from "../../images/pwa-steps/Translated.svg"
import Detail5 from "../../images/pwa-steps/Compatible.svg"
import Detail6 from "../../images/pwa-steps/Secure.svg"
import "./details-B.css"

const DetailsB = () => (
    <div className="container detailsB-container">
        <div className="detailsB-format">
            <div className="detailsB-contentContainer">
                <img src={Detail1} />
                <h3> Text to call </h3>
                <p> Instantly converts text to a voice call in non-text-911 enabled areas </p>
            </div> 

            <div className="detailsB-contentContainer">
                <img src={Detail2} />
                <h3> Free to use </h3>
                <p> Our nonprofit organization will never charge people for access to emergency services </p>
            </div> 

            <div className="detailsB-contentContainer">
                <img src={Detail3} />
                <h3> Real time </h3>
                <p> Emergency services are notified of your emergency immediately </p>
            </div> 

            <div className="detailsB-contentContainer">
                <img src={Detail4} />
                <h3> Translated </h3>
                <p>We support English, Spanish, Chinese (Simplified), Vietnamese and Arabic</p>
            </div> 

            <div className="detailsB-contentContainer">
                <img src={Detail5} />
                <h3> Compatible </h3>
                <p> Works on both iOS and Android mobile devices </p>
            </div> 

            <div className="detailsB-contentContainer">
                <img src={Detail6} />
                <h3> Secure </h3>
                <p> We never sell your data </p>
            </div> 
        </div>
    </div>
)

export default DetailsB